import { reactive, inject, computed, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { useStore } from "vuex";
export default function useUser() {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const $http = inject("$http");
  const $q = useQuasar();

  let curUser = computed(() => store.state.user);

  let data = reactive({
    customer: [],

    selCustomer: {},

    application: [],
    type: [],
    city: ["jabodetabek", "bandung", "serang", "lampung"],

    optYear: [],
    optMonth: [],
  });

  let getCity = async () => {
    let resp = await $http.get(`/list-kota`);
    data.city = resp.data;
  };

  let pagination = reactive({
    filterType: [],
    filterDate: { from: null, to: null },
    currentPage: 1,
    totalPage: 1,
    rowPerPage: 10,
  });

  let getCustomer = async (id, is_active) => {
    let resp = null;
    // if (curUser.value.is_sales == "1") {
    //   resp = await $http.get(`/customer/list-customer/${id}`);
    // } else {
    //   resp = await $http.get(`/customer/list-customer`);
    // }
    resp = await $http.get(`/customer/list-customer/${id}/${is_active}`);
    for (let item of resp.data) {
      item.label = item.name;
    }
    data.customer = resp.data;
  };

  let getDetail = async (id) => {
    let resp = await $http.get(`/customer/detail/${id}`);
    resp.data.id_sales = { label: resp.data.nama_user, id: resp.data.id_sales };
    data.selCustomer = resp.data;
  };

  let createCustomer = async () => {
    data.selCustomer.id_sales = curUser.value.id;
    if (data.selCustomer.application == "Other") {
      data.selCustomer.application = data.selCustomer.application_other;
    }
    try {
      $q.loading.show();
      await $http.post(`/customer/create`, data.selCustomer);
      router.push(`/customer/list/${curUser.value.id}`);
      data.selCustomer = {};
      $q.loading.hide();
    } catch (err) {
      let resp = await $http.get(
        `/customer/duplicate/${data.selCustomer.name}`
      );
      $q.loading.hide();
      $q.notify({
        message: `Nama Customer Sudah Terdaftar Pada Sales ${resp.data.username}`,
        color: "negative",
      });
    }
  };

  let getApplication = async () => {
    let resp = await $http.get(`/customer/list-application`);
    let temp = [];
    for (let item of resp.data) {
      temp.push(item.type);
    }
    data.application = temp;
  };

  let getType = async () => {
    let resp = await $http.get(`/customer/list-type`);
    let temp = [];
    for (let item of resp.data) {
      temp.push(item.name);
    }
    data.type = temp;
  };

  let updateCustomer = async () => {
    data.selCustomer.id_sales = data.selCustomer.id_sales.id;
    if (data.selCustomer.application == "Other") {
      data.selCustomer.application = data.selCustomer.application_other;
    }
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      await $http.post(`/customer/update`, data.selCustomer);
      router.go(-1);
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
  };

  // let getHistory = async (id) => {
  //   let resp = await $http.get(`/customer/visithistory/${id}`);
  //   data.selCustomer = resp.data;
  // };

  let getHistory = async (id) => {
    pagination.offset = pagination.rowPerPage * (pagination.currentPage - 1);
    pagination.limit = pagination.rowPerPage;
    let resp = await $http.post(`/customer/visithistory/${id}`, pagination);
    data.selCustomer = resp.data;
    console.log(data.selCustomer);

    if (data.selCustomer.visit_list.length > 0) {
      pagination.totalPage = Math.ceil(
        resp.data.total_row / pagination.rowPerPage
      );
    }
  };

  let updateCustomerType = async (new_type) => {
    try {
      $q.loading.show({ message: "Mohon tunggu sebentar" });
      data.selCustomer.type_after = new_type.type_after;
      data.selCustomer.note = new_type.note;
      data.selCustomer.type_before = data.selCustomer.type;
      await $http.post(`/customer/update-type`, data.selCustomer);
      router.go(-1);
      $q.loading.hide();
    } catch (err) {
      $q.loading.hide();
    }
  };

  let getOptYear = async () => {
    let temp = [];
    let resp = await $http.get(`/customer/minmaxyear`);
    for (let item of resp.data) {
      temp.push(item.year);
    }
    data.optYear = temp;
  };

  let getOptMonth = async (year) => {
    let temp = [];
    let resp = await $http.get(`/customer/minmaxmonth/${year}`);
    for (let item of resp.data) {
      temp.push(item.month);
    }
    data.optMonth = temp;
  };

  return {
    data,
    router,
    route,
    curUser,
    getCustomer,
    createCustomer,
    getApplication,
    getType,
    getDetail,
    updateCustomer,
    getHistory,
    updateCustomerType,
    getOptYear,
    getOptMonth,
    getCity,
    pagination,
    $q,
  };
}
