<template>
  <div class="l-wrapper">
    <q-scroll-area class="l-grow q-pa-md">
      <div class="column q-col-gutter-sm">
        <q-input
          outlined
          readonly
          label="Customer Type"
          v-model="customer.data.selCustomer.type"
          ><template v-slot:append>
            <q-btn
              flat
              dense
              icon="edit"
              :to="`/customer/changetype/${customer.route.params.id}`"
            /> </template
        ></q-input>
        <q-select
          outlined
          :options="sales.data.sales"
          v-model="customer.data.selCustomer.id_sales"
          label="Sales"
          v-can="['ADMIN']"
        ></q-select>
        <q-input
          outlined
          label="Company Name"
          v-model="customer.data.selCustomer.name"
        ></q-input>
        <q-select
          outlined
          label="City"
          :options="customer.data.city"
          v-model="customer.data.selCustomer.city"
        ></q-select>
        <q-select
          outlined
          label="Aplication"
          :options="customer.data.application"
          v-model="customer.data.selCustomer.application"
        ></q-select>

        <q-input
          outlined
          v-if="customer.data.selCustomer.application == 'Other'"
          v-model="customer.data.selCustomer.application_other"
        ></q-input>

        <q-input
          outlined
          v-model="customer.data.selCustomer.contact"
          label="kontak"
        ></q-input>
        <q-input
          outlined
          v-model="customer.data.selCustomer.detail"
          type="textarea"
          placeholder="Contact And Details"
        ></q-input>
        <q-checkbox
          label="active"
          true-value="1"
          false-value="0"
          v-model="customer.data.selCustomer.is_active"
        ></q-checkbox>
      </div>
    </q-scroll-area>
    <q-btn
      unelevated
      label="save"
      color="primary"
      @click="customer.updateCustomer"
    ></q-btn>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { ref, reactive } from "vue";
import { onMounted } from "vue";
import useSales from "../Sales/useSales";
import useCustomer from "./useCustomer";
export default {
  setup() {
    const store = useStore();
    const sales = useSales();
    const customer = useCustomer();

    onMounted(async () => {
      try {
        customer.$q.loading.show({ message: "Mohon tunggu sebentar" });
        await customer.getApplication();
        await customer.getType();
        await customer.getDetail(customer.route.params.id);
        await sales.getSales();
        customer.$q.loading.hide();
      } catch (err) {
        customer.$q.loading.hide();
      }
    });

    return {
      sales,
      customer,
      store,
    };
  },
};
</script>
